<template>
  <v-container>
    <portal to="appBarContentLeft">
      <span class="headline font-weight-light">Reports</span>
    </portal>
    <v-row justify="center">
      <v-card max-width="640" flat>
        <v-card-title>
          <span>Reports have moved</span>
        </v-card-title>

        <v-card-text>
          The billing reports have been updated and are now located in a new reporting platform which will allow for better customization and insight.
          The new reporting platform is located at
          <a
            href="https://partners.novorpm.com"
            target="_blank"
          >partners.novorpm.com</a>.
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn depressed @click="reportsHaveMoved = false">cancel</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            @click="openNewBillingPlatform"
          >open new reporting platform</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
// import InteractiveBillingPanel from '@/components/InteractiveBillingPanel'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [],
  data() {
    return {
      chartNotes: [],
      updatingNotes: false,
      reportsHaveMoved: false,
    }
  },
  // components: {
  //   InteractiveBillingPanel,
  // },
  computed: {
    ...mapState('patient', ['patient']),
  },
  methods: {
    ...mapActions('patient', ['search', 'getPatient']),
    openNewBillingPlatform() {
      this.isTracking = true
      this.child = window.open('http://partners.novorpm.com', 'null', null)
    },
  },
  mounted() {
    this.reportsHaveMoved = true
  },
}
</script>

<style>
</style>
